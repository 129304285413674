<template>
  <div class="page-container">
    <div class="banner">
      Events
    </div>
    <div class="container news-container">
      <b-row>
        <b-col
          cols="12"
          sm="3"
        >
          <div>
            <div style="font-weight: bold;font-size: 18px;">
              Categories
            </div>
            <ul class="list mt-2">
              <router-link
                class="item"
                :class="{active:type==='upcoming'}"
                :to="{name:'Events'}"
              >
                <i class="fas el-icon-fa-caret-right" /> <span class="ml-2">Events</span>
              </router-link>
              <router-link
                class="item"
                :class="{active:type==='news'}"
                :to="{name:'News'}"
              >
                <i class="fas el-icon-fa-caret-right" /> <span class="ml-2">News</span>
              </router-link>
              <router-link
                class="item"
                :class="{active:type==='insights'}"
                :to="{name:'Insights'}"
              >
                <i class="fas el-icon-fa-caret-right" /> <span class="ml-2">Insights</span>
              </router-link>
            </ul>
            <div class="text-center follow mt-4 d-none d-sm-block">
              <img
                src="../assets/images/event/yafo_life.jpeg"
                alt=""
              >
              <div class="mt-2">
                <a>Follow us on Wechat</a>
              </div>
            </div>
            <div class="text-center follow mt-3 d-none d-sm-block">
              <img
                src="../assets/images/event/linkin.png"
                alt=""
              >
              <div class="mt-2">
                <a
                  href="https://www.linkedin.com/company/yafo-capital"
                  target="_blank"
                >Follow us on LinkedIn</a>
              </div>
            </div>
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="9"
        >
          <external-link
            v-for="item in events"
            :key="item.id"
            :external="item.content_url"
            :to="{name:'Event Detail',params:{id:item.id}}"
            class="media news"
          >
            <img
              v-if="item.cover"
              :src="item.cover.url | getSource"
              alt=""
            >
            <div class="media-body">
              <div class="title">
                {{ item.title }}
              </div>
              <div class="time">
                <b-icon icon="calendar-check" />
                {{ item.time }}
              </div>
              <div class="content">
                {{ item.introduction }}
              </div>
            </div>
          </external-link>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { getList } from '@/api/event'
import ExternalLink from '@/components/ExternalLink'

export default @Component({
  components: {
    ExternalLink
  }
})
class Event extends Vue {
  @Prop({ required: true, default: 'upcoming' }) type

  events = []

  mounted () {
    this.getEventList()
  }

  @Watch('$i18n.locale')
  async getEventList () {
    this.events = await getList({ type: this.type })
    console.log(this.events)
  }
}
</script>

<style lang="scss" scoped>
.banner {
  background: url("../assets/images/news/banner.jpeg");
}

.list {
  font-size: 14px;
  list-style: none;
  padding-left: 0;

  .item {
    display: block;
    cursor: pointer;
    height: 56px;
    line-height: 56px;
    color: #212529;
    border-bottom: 1px solid #e9e9e9;

    &.active {
      color: #e49100;
    }
  }
}

.follow {
  font-size: 14px;

  img {
    width: 7.125rem;
    height: 7.125rem;
  }
}
</style>
